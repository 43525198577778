
import { defineComponent } from 'vue';
import PageHeader from '../components/PageHeader.vue'
import { getStaffProfile, ParsedItem } from '../lib/Contentful'

interface ProfileData {
  profile: ParsedItem;
}

export default defineComponent({
  name: 'StaffProfile',
  components: {
    PageHeader
  },
  data(): ProfileData {
    return {
      profile: null
    }
  },
  async mounted() {
    const url: string = this.$route.params.name as string
    await this.getStaffProfile(url)
    const w = window as any;
    w.fbq('track', 'ViewContent');
  },
  methods: {
    async getStaffProfile(url: string) {
      this.profile = await getStaffProfile(url)
    }
  }
});
